/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

type SEOProps = {
  description?: string;
  title?: string;
  lang?: string;
  keywords?: string[];
  url: string;
};
const SEO: React.FC<SEOProps> = ({
  description,
  title,
  lang,
  keywords,
  url,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
            url
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = title || site.siteMetadata?.title;
  const defaultLang = lang || SEO.defaultProps?.lang;
  const defaultImage = site.siteMetadata?.image;
  const siteAuthor = site.siteMetadata?.author;
  const siteUrl = url || site.siteMetadata?.url;
  const metaKeywords = keywords || [
    "racial profiling",
    "racial profiling de",
    "racial profiling deutschland",
    "racial profiling deutsch",
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={defaultTitle}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: siteUrl,
        },
        {
          property: `og:title`,
          content: defaultTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: defaultImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          property: `keywords`,
          content: metaKeywords,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: defaultTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: defaultImage,
        },
      ].concat([])}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  description: ``,
};

export default SEO;
